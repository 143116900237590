import React from "react"
import moment from "moment"
import { graphql, Link } from "gatsby"
import { Timeline } from 'react-twitter-widgets'
import BooksLoop from "../components/booksLoop"
import NewsLoop from "../components/newsLoop"
import Layout from "../components/layout"
import ReadMore from "../components/btnReadMore"
import SEO from "../components/seo"
import { IndxBooks, IndxBooksListWrapper, IndxBooksHdr, IndxNews, IndxNewsListWrapper, IndxNewsHdr, IndxNewsBdy, IndxBooksBdy, IndxNoteTtl, IndxNoteBdy, IndxOrder, IndxOrderGroup, IndxOrderLg, IndxOrderSm, IndxSNS, IndxNote, NotePost, NotePostDate, NotePostTtl, IndxTwitter, BtnWrapper} from "../style/common/common"

const IndexPage = ({ data, location }) => {
  const NewsPosts = data.news.edges
    .filter(edge => !!edge.node.frontmatter.date)
    .map(edge => <NewsLoop post={edge.node} />)
  const BooksPosts = data.books.edges
    .filter(edge => !!edge.node.frontmatter.date)
    .map(edge => <BooksLoop post={edge.node} />)

  return (
    <Layout
      location={location}
    >
      <SEO/>
      <IndxBooks>
        <IndxBooksHdr>
          <h2>刊行書籍</h2>
        </IndxBooksHdr>
        <IndxBooksListWrapper>
          <IndxBooksBdy>
            {BooksPosts}
          </IndxBooksBdy>
          <ReadMore to="/books/" label="もっと見る"/>
        </IndxBooksListWrapper>
      </IndxBooks>
      <IndxNews>
        <IndxNewsHdr>
          <h2>お知らせ</h2>
        </IndxNewsHdr>
        <IndxNewsListWrapper>
          <IndxNewsBdy>
            {NewsPosts}
          </IndxNewsBdy>
          <ReadMore to="/news/" label="もっと見る"/>
        </IndxNewsListWrapper>
      </IndxNews>
      <IndxOrder>
        <IndxOrderGroup>
          <h2>個人さまのご注文はこちら</h2>
          <IndxOrderLg>
            <a href="https://soryusha.stores.jp/" target="_blank" rel="noopener noreferrer">素粒社ウェブショップ</a>
          </IndxOrderLg>
        </IndxOrderGroup>
        <IndxOrderGroup>
          <h2>書店さまのご注文はこちら</h2>
          <IndxOrderSm>
            <Link to="/howtoorder/">お取引について</Link>
          </IndxOrderSm>
          <IndxOrderSm>
            <a href="https://1satsu.jp/publisher/244/" target="_blank" rel="noopener noreferrer">
              <span className="icon-1satsu"><img src={`../../icons/icon-1satsu.svg`} alt="1冊！ 取引所"/></span>
            </a>
          </IndxOrderSm>
          <IndxOrderSm>
            <a href="https://www.bookcellar.jp/publishertop/list/387" target="_blank" rel="noopener noreferrer">
              <span className="icon-bookcellar"><img src={`../../icons/icon-bookcellar.svg`} alt="bookcellar"/></span>
            </a>
          </IndxOrderSm>
        </IndxOrderGroup>
      </IndxOrder>
      <IndxSNS>
        <IndxNote>
          <IndxNoteTtl>
            <img src={`../../icons/icon-note-lg.svg`} alt="note"/>
          </IndxNoteTtl>
          {/* <h2>note</h2> */}
          <IndxNoteBdy>
            {(data.allFeednote.nodes).map(post => {
              return(
                <NotePost key={post.id}>
                  <a href={post.link} target="_blank" rel="noopener noreferrer">
                    <NotePostDate>
                      {/* {post.pubDate} */}
                      {moment(post.pubDate).format(`YYYY.MM.DD`)}
                    </NotePostDate>
                    <NotePostTtl>{post.title}</NotePostTtl>
                  </a>
                </NotePost>
              )
            })}
          </IndxNoteBdy>
          <BtnWrapper>
            <a href="https://note.com/soryusha/" target="_blank" rel="noopener noreferrer">もっと見る</a>
          </BtnWrapper>
        </IndxNote>

        {/* <Tweet tweetId="841418541026877441" /> */}
        <IndxTwitter>
          <Timeline
            dataSource={{
              sourceType: 'profile',
              screenName: 'soryusha_books',
              // id: "soryusha_books"
            }}
            options={{
              lang: "ja",
              width: "100%",
              height: "500",
              // theme: "dark",
            }}
          />
        </IndxTwitter>
      </IndxSNS>
    </Layout>
  )
}

export default IndexPage

export const listquery = graphql`
  query ListQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
    news: allMarkdownRemark(
      sort: {order: DESC, fields: [frontmatter___date] }
      filter: {frontmatter: {posttype: {eq: "news"}}}
      limit: 6
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY.MM.DD")
            title
            category
            thumb {
              childImageSharp {
                gatsbyImageData(width: 400)
              }
            }
          }
        }
      }
    }
    books: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {frontmatter: {posttype: {eq: "books"}}}
      limit: 12
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY.MM.DD")
            title
            author
            category
            pubDate
            thumb {
              childImageSharp {
                gatsbyImageData(width: 400)
              }
            }
            badge
          }
        }
      }
    }
    allFeednote (sort: {fields: isoDate, order: DESC}, limit: 4){
      nodes {
        link
        title
        pubDate
        id
      }
    }
  }
`
